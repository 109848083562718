exports.components = {
  "component---src-components-templates-article-article-js": () => import("./../../../src/components/templates/Article/Article.js" /* webpackChunkName: "component---src-components-templates-article-article-js" */),
  "component---src-components-templates-category-category-js": () => import("./../../../src/components/templates/Category/Category.js" /* webpackChunkName: "component---src-components-templates-category-category-js" */),
  "component---src-components-templates-category-category-list-js": () => import("./../../../src/components/templates/Category/CategoryList.js" /* webpackChunkName: "component---src-components-templates-category-category-list-js" */),
  "component---src-components-templates-meta-meta-js": () => import("./../../../src/components/templates/Meta/Meta.js" /* webpackChunkName: "component---src-components-templates-meta-meta-js" */),
  "component---src-components-templates-start-start-js": () => import("./../../../src/components/templates/Start/Start.js" /* webpackChunkName: "component---src-components-templates-start-start-js" */),
  "component---src-components-templates-tag-page-tag-page-js": () => import("./../../../src/components/templates/TagPage/TagPage.js" /* webpackChunkName: "component---src-components-templates-tag-page-tag-page-js" */),
  "component---src-components-templates-topic-topic-js": () => import("./../../../src/components/templates/Topic/Topic.js" /* webpackChunkName: "component---src-components-templates-topic-topic-js" */),
  "component---src-components-templates-topic-topic-list-js": () => import("./../../../src/components/templates/Topic/TopicList.js" /* webpackChunkName: "component---src-components-templates-topic-topic-list-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-pages-suche-js": () => import("./../../../src/pages/suche.js" /* webpackChunkName: "component---src-pages-suche-js" */)
}

