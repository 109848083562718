require('./src/css/tailwind-base.css')
require('./src/css/tailwind-components.css')
require('./src/css/tailwind-utilities.css')
require('./src/css/tailwind-variants.css')

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { hash } = location
  if (hash) {
    return true
  } else {
    window.scrollTo(0, 0)
    return false
  }
}
